<template>
  <div>
    <h2>Weekly DPO Stats</h2>
    <div class="date-inputs">
      <label for="startDate">Start Date:</label>
      <input type="date" id="startDate" v-model="startDate" />

      <label for="endDate">End Date:</label>
      <input type="date" id="endDate" v-model="endDate" />

      <button @click="fetchWeeklyData">Fetch Weekly Data</button>
    </div>

    <!-- Weekly Stats Table -->
    <table v-if="weeklyStats.length > 0">
      <thead>
        <tr>
          <th>Week #</th>
          <th>Week Start</th>
          <th>Week End</th>
          <th>Total DPO Sets | -14% Adjustment</th>
          <th>Days in Range</th>
          <th>Average Daily | -14% Adjustment</th>
          <th>Est. Weekly Income (€) | -14% Adjustment</th>
          <th>Est. Daily Income (€) | -14% Adjustment</th>
        </tr>
      </thead>
      <tr v-for="week in weeklyStats" :key="week.week_start">
        <td>{{ getWeekNumber(week.week_start) }}</td>
        <td>{{ week.week_start }}</td>
        <td>{{ week.week_end }}</td>
        <td>{{ week.total_count }} | {{ week.total_count * 0.86 }}</td>
        <td>{{ week.days_in_range }}</td>
        <td>{{ week.average_daily.toFixed(2) }} | {{ (week.average_daily * 0.86).toFixed(2) }}</td>
        <td>{{ ((week.total_count / week.days_in_range) * 7 * 0.04).toFixed(2) }} | {{ (0.86 * (((week.total_count / week.days_in_range) * 7 * 0.04)).toFixed(2)) }}</td>
        <td>{{ (week.average_daily * 0.04).toFixed(2) }} | {{ (week.average_daily * 0.04 * 0.86).toFixed(2) }}</td>
      </tr>
    </table>

    <h2>Monthly Earnings Stats</h2>
    <button @click="fetchMonthlyData">Fetch Monthly Earnings</button>

    <!-- Monthly Stats Table -->
    <table v-if="monthlyStats.length > 0">
      <thead>
        <tr>
          <th>Month Start</th>
          <th>Month End</th>
          <th>Total DPO Sets</th>
          <th>Estimated Individual Earning (€) | -14% Adjustment</th>
        </tr>
      </thead>
      <tr v-for="month in monthlyStats" :key="month.month_start">
        <td>{{ month.month_start }}</td>
        <td>{{ month.month_end }}</td>
        <td>{{ month.total_count }}</td>
        <td>{{ month.computed_value.toFixed(2) }} | {{ (month.computed_value * 0.86).toFixed(2) }}</td>
      </tr>
    </table>

    <div v-if="!loading && monthlyStats.length === 0">
      <p>Press Fetch Data Button to Fetch the Data :)</p>
    </div>
  </div>
</template>
<script>

export default {
  data() {
    const today = new Date().toISOString().split("T")[0]; // Today's date in YYYY-MM-DD format
    const startOfYear = `${new Date().getFullYear()}-01-01`; // Start of the current year

    return {
      startDate: startOfYear,
      endDate: today,
      weeklyStats: [],
      monthlyStats: [],
      loading: false,
      error: null,
    };
  },
  methods: {
    async fetchWeeklyData() {
      this.loading = true;
      this.error = null;
      this.weeklyStats = [];

      try {
        const queryParams = new URLSearchParams({
          start_date: this.startDate,
          end_date: this.endDate,
        }).toString();

        const response = await fetch(
          `${process.env.VUE_APP_API_BASE_URL}/api/users/average_daily_dposets_by_week/?${queryParams}`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Token ${localStorage.getItem("token")}`,
            },
          }
        );

        if (!response.ok) {
          throw new Error("Failed to fetch weekly data");
        }

        this.weeklyStats = await response.json();
      } catch (error) {
        console.error("Error fetching weekly stats:", error);
        this.error = "Failed to load weekly data. Please try again.";
      } finally {
        this.loading = false;
      }
    },

    async fetchMonthlyData() {
      this.loading = true;
      this.error = null;
      this.monthlyStats = [];

      try {
        const queryParams = new URLSearchParams({
          start_date: this.startDate,
          end_date: this.endDate,
        }).toString();

        const response = await fetch(
          `${process.env.VUE_APP_API_BASE_URL}/api/users/monthly_dposet_chosen_answer/?${queryParams}`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Token ${localStorage.getItem("token")}`,
            },
          }
        );

        if (!response.ok) {
          throw new Error("Failed to fetch monthly data");
        }

        this.monthlyStats = await response.json();
      } catch (error) {
        console.error("Error fetching monthly stats:", error);
        this.error = "Failed to load monthly data. Please try again.";
      } finally {
        this.loading = false;
      }
    },

    getWeekNumber(dateString) {
      const date = new Date(dateString);

      // Set the day to Thursday of the current week (ISO standard)
      const target = new Date(date.valueOf());
      target.setDate(target.getDate() - ((date.getDay() + 6) % 7) + 3);

      // First Thursday of the year
      const firstThursday = new Date(target.getFullYear(), 0, 4);
      firstThursday.setDate(firstThursday.getDate() - ((firstThursday.getDay() + 6) % 7) + 3);

      // Calculate week number
      const weekNumber = Math.floor(((target - firstThursday) / 86400000 + 1) / 7) + 1;

      return weekNumber;
    }
  },
  created() {
    this.fetchWeeklyData();
  },
};
</script>

<style>
.date-inputs {
  margin-bottom: 20px;
}

.date-inputs label {
  margin-right: 10px;
}

.date-inputs input[type="date"] {
  margin-right: 20px;
}

button {
  padding: 10px 20px;
  background-color: #0056b3;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

button:hover {
  background-color: #004494;
}

table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
}

th,
td {
  border: 1px solid #ddd;
  padding: 10px;
  text-align: center;
}

th {
  background-color: #f4f4f4;
}

.loading-message,
.error-message {
  margin-top: 20px;
  color: red;
  font-weight: bold;
}
</style>