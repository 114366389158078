<template>
  <div>
    <h2>DPO Stats Report</h2>
    <div class="date-inputs">
      <label for="startDate">Start Date:</label>
      <input type="date" id="startDate" v-model="startDate">

      <label for="endDate">End Date:</label>
      <input type="date" id="endDate" v-model="endDate">
    </div>
    <h3>Total DPO Sets: {{ totalDpoSets }}</h3>
    
    <table>
      <thead>
        <tr>
          <th>Username</th>
          <th @click="sortData('dpo_count')" class="sortable">DPO Sets Count <span v-if="sortColumn === 'dpo_count'">{{ sortOrder }}</span></th>
          <th @click="sortData('similarity_rating')" class="sortable">Similarity Rating (%) <span v-if="sortColumn === 'similarity_rating'">{{ sortOrder }}</span></th>
          <th @click="sortData('sample_size')" class="sortable">Sample Size <span v-if="sortColumn === 'sample_size'">{{ sortOrder }}</span></th>
        </tr>
      </thead>
      <tbody>
        <tr v-if="loading">
          <td colspan="4" class="loading-text">Loading...</td>
        </tr>
        <tr v-for="user in sortedUsers" :key="user.username">
          <td>
            <a :href="`/dpo-messages?username=${user.username}&startDate=${startDate}&endDate=${endDate}`" target="_blank">
              {{ user.username }}
            </a>
          </td>
          <td>{{ user.dpo_count ?? 'Loading...' }}</td>
          <td v-if="user.similarity_rating !== null">{{ (user.similarity_rating * 100).toFixed(2) }}%</td>
          <td v-else>N/A</td>
          <td v-if="user.sample_size !== null">{{ user.sample_size }}</td>
          <td v-else>N/A</td>
        </tr>
      </tbody>
    </table>
  </div>
</template>
  
<script>
import { formatStartOfMonth, formatToday, formatDate } from '@/utils/dateUtils';

export default {
  data() {
    const storedStartDate = localStorage.getItem('startDate');
    const storedEndDate = localStorage.getItem('endDate');

    return {
      users: [],
      totalDpoSets: 0,
      startDate: storedStartDate || formatStartOfMonth(),
      endDate: storedEndDate || formatToday(),
      loading: true,
      sortColumn: null,
      sortOrder: '▲',
    };
  },
  computed: {
    sortedUsers() {
      if (!this.sortColumn) return this.users;
      return [...this.users].sort((a, b) => {
        const aValue = a[this.sortColumn] ?? 0;
        const bValue = b[this.sortColumn] ?? 0;
        return this.sortOrder === '▲' ? bValue - aValue : aValue - bValue;
      });
    }
  },
  created() {
    this.getToken();
    this.fetchDpoData();
  },
  methods: {
    getToken() {
      this.token = localStorage.getItem('token');
      if (!this.token) {
        console.error('No token found');
        return;
      }
    },
    async fetchDpoData() {
      this.loading = true;
      try {
        const response = await fetch(
          `${process.env.VUE_APP_API_BASE_URL}/api/users/all-dpo-by-date/`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Token ${this.token}`,
            },
            body: JSON.stringify({
              start: this.startDate + "T00:00:00",
              end: this.endDate + "T23:59:59",
            }),
          }
        );
        const data = await response.json();
        this.totalDpoSets = data.total_dpo_sets;

        const ratingsPromises = data.users.map(async (user) => {
          try {
            const ratingResponse = await fetch(
              `${process.env.VUE_APP_API_BASE_URL}/api/users/igor_rating/?username=${user.username}&start=${this.startDate}T00:00:00&end=${this.endDate}T23:59:59`,
              {
                method: "GET",
                headers: {
                  "Content-Type": "application/json",
                  Authorization: `Token ${this.token}`,
                },
              }
            );

            if (!ratingResponse.ok) {
              throw new Error(`Failed to fetch rating for user: ${user.username}`);
            }

            const ratingData = await ratingResponse.json();
            return { ...user, similarity_rating: ratingData.similarity_rating ?? null, sample_size: ratingData.sample_size ?? null };
          } catch (err) {
            console.error(`Error fetching rating for user ${user.username}:`, err);
            return { ...user, similarity_rating: null, sample_size: null };
          }
        });

        this.users = await Promise.all(ratingsPromises);
      } catch (error) {
        console.error("Error fetching users or ratings:", error);
      } finally {
        this.loading = false;
      }
    },
    sortData(column) {
      if (this.sortColumn === column) {
        this.sortOrder = this.sortOrder === '▲' ? '▼' : '▲';
      } else {
        this.sortColumn = column;
        this.sortOrder = '▲';
      }
    }
  },
  watch: {
    startDate(newVal) {
      const formattedDate = formatDate(new Date(newVal));
      localStorage.setItem('startDate', formattedDate);
      this.fetchDpoData();
    },
    endDate(newVal) {
      const formattedDate = formatDate(new Date(newVal));
      localStorage.setItem('endDate', formattedDate);
      this.fetchDpoData();
    },
  },
};
</script>
  
  <style>
  body {
    font-family: 'Roboto', sans-serif;
    color: #333;
    background-color: #f8f8f8;
    margin: 0;
    padding: 0;
  }
  
  h2, h3 {
    color: #0056b3;
  }
  
  .date-inputs {
    margin-bottom: 20px;
  }
  
  .date-inputs label {
    margin-right: 10px;
    font-weight: bold;
  }
  
  .date-inputs input[type="date"] {
    border: 1px solid #ccc;
    border-radius: 4px;
    padding: 5px;
    margin-right: 20px;
  }
  
  table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 20px;
  }
  
  th, td {
    border: 1px solid #ddd;
    padding: 12px;
    text-align: left;
  }
  
  th {
    background-color: #0066cc;
    color: white;
  }
  
  tr:nth-child(even) {
    background-color: #f2f2f2;
  }

  .pagination-controls {
    margin-top: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
  }

  .go-to-page {
    display: flex;
    align-items: center;
    gap: 5px;
  }

  .go-to-page input {
    width: 60px;
    padding: 5px;
    border: 1px solid #ccc;
    border-radius: 4px;
    text-align: center;
  }

  .go-to-page button {
    padding: 5px 10px;
    background-color: #4CAF50;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }

  .go-to-page button:hover {
    background-color: #45a049;
  }

  .loading-text {
    text-align: center;
    font-size: 18px;
    font-weight: bold;
    color: #ff5722;
  }

  .sortable {
    cursor: pointer;
  }
  </style>